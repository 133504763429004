import * as React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Master from 'layouts/Master';
import SeoHelmet from 'components/Seo/SeoHelmet';
import { PageNode } from 'models/Page';
import PageUtils from 'utils/PageUtils';
import DateUtils from 'utils/DateUtils';
import GenericUtils from 'utils/GenericUtils';

type Props = {
  pageContext: {
    slug: string;
  };
  data: {
    article: PageNode;
  }
};

export default class Article extends React.Component<Props> {
  render() {
    const { slug } = this.props.pageContext;
    const node = this.props.data.article;

    /*
    if (!GenericUtils.isServerSideRendering() && typeof IntersectionObserver !== 'undefined') {
      const obsCallback = (entries: IntersectionObserverEntry[]) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            (entry.target as HTMLVideoElement).play();
          } else {
            (entry.target as HTMLVideoElement).pause();
          }
        });
      };

      const observer = new IntersectionObserver(obsCallback);
      const targets = document.querySelectorAll('video');
      targets.forEach(t => {
        observer.observe(t);
      });
    }
    */

    return (
      <Master title={PageUtils.generateTitle(node.frontmatter.title)}>
        <SeoHelmet page={{ path: slug, node: node, type: 'page' }} />

        <div className="container">
          <article>
            <header className="article-template__header">
              {
                node.frontmatter.remoteThumbnail
                  ? <img src={node.frontmatter.remoteThumbnail} alt={node.frontmatter.title} title={node.frontmatter.title} className="article-template__header__thumbnail" />
                  : <Img fixed={node.frontmatter.thumbnail!.childImageSharp!.fixed!} alt={node.frontmatter.title} title={node.frontmatter.title} className="article-template__header__thumbnail" />
              }
              <div className="article-template__header__info-container">
                <h1 className="article-template__header__title">
                  {node.frontmatter!.title}
                </h1>
                <time className="article-template__header__date">
                  {DateUtils.format(node.frontmatter.date)}
                </time>
              </div>
            </header>
            <div dangerouslySetInnerHTML={{ __html: node.html || '' }} className="article-template__page page"/>
          </article>
        </div>
      </Master>
    );
  }
}

export const ArticleQuery = graphql`
  query ArticleBySlug($slug: String!) {
    article: markdownRemark(frontmatter: { template: { eq: "article" } }, fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        tags
        template
        date
        remoteThumbnail
        thumbnail {
          childImageSharp {
            fixed(width: 800) {
              base64
              width
              height
              src
              srcSet
            }
          }
        }
      }
      fields {
        slug
      }
    }
  }
`;
